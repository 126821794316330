import { HttpErrorResponse } from "@angular/common/http";

const handleHttpErrors = (err: HttpErrorResponse) => {
  const errors: any[] = err?.error?.errors;

  if (!errors || errors.length === 0) return "Something went wrong";

  const firstError = errors.at(0);

  if (typeof firstError === "string") {
    return firstError;
  }
  return firstError?.error ?? "Something went wrong";
};

export default handleHttpErrors;
